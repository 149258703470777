import React from 'react';
import PropTypes from 'prop-types';
import ErrorBlock from '@/components/ErrorBlock/Loadable';
import LoadingSVG from '@/components/LoadingSVG';

export const LoadingPlaceholder = LoadingSVG;

const LoadingComponent = ({ isLoading, error, pastDelay }) => {
  if (isLoading && pastDelay) {
    return (<LoadingPlaceholder />);
  } else if (error) {
    return <ErrorBlock />;
  }

  // Don't flash "Loading..." when we don't need to.
  return null;
};

LoadingComponent.propTypes = {
  isLoading: PropTypes.bool,
  error: PropTypes.instanceOf(Error),
  pastDelay: PropTypes.bool,
};

export default LoadingComponent;
