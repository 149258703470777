import React, { memo } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import LegacyMobilePage from '@/components/LegacyMobilePage/Loadable';
import ClipLoadable from '@/containers/Clip/components/Loadable';
import ClipEventLoadable from '@/containers/ClipEvent/components/Loadable';

import MaintenanceNotFoundLoadable from '@17live/app/components/Maintenance/MaintenanceNotFound.loadable';
import MaintenancePrivateContentLoadable from '@17live/app/components/Maintenance/MaintenancePrivateContent.loadable';
import MaintenanceSystemMaintenanceLoadable from '@17live/app/components/Maintenance/MaintenanceSystemMaintenance.loadable';
import MaintenanceSystemOverloadLoadable from '@17live/app/components/Maintenance/MaintenanceSystemOverload.loadable';
import {
  AvatarRoutePath,
  ErrorRoutePath,
  WebViewRoutePath,
} from '@17live/app/constants/routePath';
import AppRedirectToAbout from '@17live/app/containers/App/AppRedirectToAbout';
import { useRedirectLangURL } from '@17live/app/containers/App/hooks/useRedirectLangURL';
import ContractApplicationRoute from '@17live/app/containers/ContractApplication/ContractApplicationRoute';
import MainLoadable from '@17live/app/containers/Main/components/Loadable';
import RevenueLegacyLoadable from '@17live/app/containers/RevenuePage/legacy/components/Loadable';
import VLiveCharacterIntroductionContainer from '@17live/app/containers/VLiveCharacter/VLiveCharacterIntroductionContainer';
import { useIsMobile } from '@17live/app/hooks';
import AvatarInfo from '@17live/app/pages/AvatarInfo';
import ExternalClipLoadable from '@17live/app/pages/ExternalClip/ExternalClip.loadable';
import ExternalLiveLoadable from '@17live/app/pages/ExternalLive';

import RedirectFromState from '../containers/RedirectFromState';

const AppRoute = () => {
  const isMobile = useIsMobile();
  const match = useRouteMatch();
  const parentPath = match.path === '/' ? '' : match.path;

  const redirectURL = useRedirectLangURL();

  return (
    <Switch>
      {!!redirectURL && <Redirect to={redirectURL} />}

      <Route
        exact
        path={`${parentPath}${ErrorRoutePath.NOT_FOUND}`}
        render={() => <MaintenanceNotFoundLoadable />}
      />

      {isMobile && [
        <Route
          path={`${parentPath}${AvatarRoutePath.AVATAR}`}
          render={props => <AvatarInfo {...props} />}
        />,
        <Route
          exact
          path={`${parentPath}${WebViewRoutePath.VLIVER}`}
          render={props => <VLiveCharacterIntroductionContainer {...props} />}
        />,
        <Route
          path={`${parentPath}${WebViewRoutePath.CONTRACT_APPLICATION_APP}`}
          render={props => <ContractApplicationRoute {...props} />}
        />,
      ]}

      <Route
        exact
        path={`${parentPath}/legacy/mobile`}
        render={props => <LegacyMobilePage {...props} />}
      />
      <Route
        exact
        path={`${parentPath}${ErrorRoutePath.SYSTEM_MAINTENANCE}`}
        render={() => <MaintenanceSystemMaintenanceLoadable />}
      />
      <Route
        exact
        path={`${parentPath}${ErrorRoutePath.OVERLOAD}`}
        render={() => <MaintenanceSystemOverloadLoadable />}
      />
      <Route
        exact
        path={`${parentPath}${ErrorRoutePath.PRIVATE_CONTENT}`}
        render={() => <MaintenancePrivateContentLoadable />}
      />
      <Route
        path={`${parentPath}/bbfu`}
        render={() => <RevenueLegacyLoadable />}
      />
      <Route
        path={`${parentPath}/recap`}
        render={props => <ClipLoadable {...props} />}
      />
      <Route
        exact
        path={`${parentPath}/clipEvent/:clipEventID`}
        render={props => <ClipEventLoadable {...props} />}
      />
      <Route
        exact
        path={`${parentPath}/redirect/:oauthType/:action?`}
        render={props => <RedirectFromState {...props} />}
      />
      <Route
        exact
        path={`${parentPath}/redirect`}
        render={props => <RedirectFromState {...props} />}
      />

      {/*
        CTWant Ad slot
        https://17media.atlassian.net/browse/APP-18075
      */}
      <Route
        exact
        path={`${parentPath}/external/live/:roomID`}
        render={props => <ExternalLiveLoadable {...props} />}
      />

      <Route
        exact
        path={`${parentPath}/external/clip/:clipID`}
        render={props => <ExternalClipLoadable {...props} />}
      />

      {/* FIXME: This route can be move to addAboutMiddlewares */}
      <Route
        exact
        path={`${parentPath}/about`}
        render={() => <AppRedirectToAbout />}
      />

      <Route component={MainLoadable} />
    </Switch>
  );
};

export default memo(AppRoute);
